import { useEffect, FormEventHandler } from 'react';
import Checkbox from '@/Components/Checkbox';
import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import PrimaryButton from '@/Components/PrimaryButton';
import TextInput from '@/Components/TextInput';
import { Link, useForm } from '@inertiajs/react';

import Head from "@/Molecules/Inertia/Head";
import {relativeRoute} from "@/helpers/relativeRoute";
import Page from "@/Pages/Page";

export default function Login({ status, canResetPassword }: { status?: string, canResetPassword: boolean }) {
    const { data, setData, post, processing, errors, reset } = useForm<{
        email: string
        password: string
        remember: boolean
    }>({
        email: '',
        password: '',
        remember: false,
    });

    useEffect(() => {
        return () => {
            reset('password');
        };
    }, []);

    const submit: FormEventHandler = (e) => {
        e.preventDefault();

        post(relativeRoute('login'));
    };

    return (
        <Page title={'Inloggen'}>

          <section>
            <div className={'container'}>
              <h1 className={'text-center'}>Aanmaken account en login</h1>
              <p>
                  Via jouw account kun je rouwpagina's aanmaken en beheren.<br/>
              </p>
            </div>

            <div className="container !max-w-md">
                {status && <div className="mb-4 font-medium text-sm text-green-600">{status}</div>}

                <form onSubmit={submit} className={' px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg'}
                      data-testid={'loginform'}>

                    <h4 className={'mb-5'}>Inloggen met jouw account</h4>
                    <div>
                        <InputLabel htmlFor="email" value="E-mailadres" data-testid={'email'}/>

                        <TextInput
                          id="email"
                          type="email"
                          name="email"
                          className="mt-1 block w-full"
                          autoComplete="username"
                          isFocused={true}
                          value={data.email}
                          onChange={(e) => setData('email', e.target.value)}
                        />

                        <InputError message={errors.email} className="mt-2"/>
                    </div>

                    <div className="mt-4">
                        <InputLabel htmlFor="password" value="Wachtwoord" data-testid={'password'}/>

                        <TextInput
                          id="password"
                          type="password"
                          name="password"
                          value={data.password}
                          className="mt-1 block w-full"
                          autoComplete="current-password"
                          onChange={(e) => setData('password', e.target.value)}
                        />

                        <InputError message={errors.password} className="mt-2"/>
                    </div>

                    <div className="block mt-4">
                        <label className="flex items-center">
                            <Checkbox
                              name="remember"
                              checked={data.remember}
                              onChange={(e) => setData('remember', e.target.checked)}
                            />
                            <span className="ms-2 text-sm text-gray-600">Ingelogd blijven</span>
                        </label>
                    </div>

                    <div className="flex items-center justify-end mt-4">
                        {canResetPassword && (
                          <Link
                            href={relativeRoute('password.request')}
                            className="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-hidden focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            data-testid={'forgot-password'}
                          >
                              Wachtwoord vergeten?
                          </Link>
                        )}

                        <PrimaryButton className="ms-4" disabled={processing} data-testid={'submit'}>
                            Inloggen
                        </PrimaryButton>

                    </div>

                </form>

                <p className={'my-5'}>Heb je nog geen account?</p>
                <div className={'text-center'}>
                <a
                  className="btn btn-primary"
                  data-testid={'register'}
                  // href={relativeRoute('onboarding.step1-account', {type: 'regulier'})}
                  href={'https://forms.office.com/e/cfzcZU9MJK'}
                >
                    Maak hier een account aan
                </a>
                </div>

                <br />
                <br />
            </div>

          </section>
        </Page>
    );
}
